export const SITE_NAME = process.env.NEXT_PUBLIC_SITE_NAME;
export const SITE_DOMAIN = process.env.NEXT_PUBLIC_SITE_DOMAIN;
export const SITE_BASE = process.env.NEXT_PUBLIC_BASE_URL;

export const STRAPI_URL = process.env.NEXT_PUBLIC_STRAPI_ENDPOINT;

export const AMT_EVENT_URL = process.env.NEXT_PUBLIC_EVENTSERVICE_REST;

export const GEOIP_URL = process.env.NEXT_PUBLIC_ABSTRACTAPI_ENDPOINT;

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID as string;
export const ONETRUST = process.env.NEXT_PUBLIC_ONETRUST;

export const OIDC_REST = process.env.NEXT_PUBLIC_OIDC_REST;
export const OIDC_AUTHORITY = process.env.NEXT_PUBLIC_OIDC_AUTHORITY;
export const OIDC_LINK_ORIGIN = process.env.NEXT_PUBLIC_OIDC_LINK_ORIGIN;
export const OIDC_PROFILE = process.env.NEXT_PUBLIC_OIDC_PROFILE;
export const OIDC_WELL_KNOWN = process.env.NEXT_PUBLIC_OIDC_WELL_KNOWN;
export const OIDC_CLIENT_ID = process.env.NEXT_PUBLIC_OIDC_CLIENT_ID;

export const SUBSCRIBE_URL = process.env.NEXT_PUBLIC_SUBSCRIBE_URL as string;

export const I18N_COOKIE = 'krafton.amt.language' as string;
export const BANNER_LS_KEY = 'krafton.amt.red.dismissed-site-banners' as string;

export const COMMUNITY_STATS_ENDPOINT = process.env.COMMUNITY_STATS_ENDPOINT as string;
export const ALL_TIME_LEADERBOARDS_ENDPOINT = process.env
    .NEXT_PUBLIC_ALL_TIME_LEADERBOARDS_ENDPOINT as string;
export const MONTHLY_LEADERBOARDS_ENDPOINT = process.env
    .NEXT_PUBLIC_MONTHLY_LEADERBOARDS_ENDPOINT as string;
export const PLAYER_PLACEMENT_ENDPOINT = process.env
    .NEXT_PUBLIC_PLAYER_PLACEMENT_ENDPOINT as string;
