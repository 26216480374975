'use client';

import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { useEffect } from 'react';

import { GTM_ID } from '@/constants';
import { useCookies } from '@/features/cookies/store/cookie.store';

export const GTMInit = () => {
    const { siteCookies } = useCookies();

    if (['functional', 'performance', 'targeting'].some((type) => siteCookies.includes(type))) {
        return <GoogleTagManager gtmId={GTM_ID} />;
    }

    return <></>;
};

export const GTMEvent = ({ pageTitle }: { pageTitle?: string }) => {
    const { siteCookies } = useCookies();

    useEffect(() => {
        if (['functional', 'performance', 'targeting'].some((type) => siteCookies.includes(type))) {
            sendGTMEvent({
                event: 'pageview',
                pagePath: window.location.pathname,
                pageTitle: pageTitle,
            });
        }
    }, [pageTitle, siteCookies]);

    return <></>;
};
