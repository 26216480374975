'use client';

import {
    Noto_Sans_KR,
    Noto_Sans_SC,
    Noto_Sans_TC,
    Noto_Sans,
    Noto_Sans_JP,
} from 'next/font/google';
import { useParams } from 'next/navigation';
import { useEffect } from 'react';

import {
    defaultLanguage,
    defaultLanguages,
} from '@/features/site-language/helpers/defaultLanguages';

export const noto_sans_kr = Noto_Sans_KR({
    subsets: ['latin'],
    weight: ['400', '700'],
});
export const noto_sans_sc = Noto_Sans_SC({
    subsets: ['latin'],
    weight: ['400', '700'],
});
export const noto_sans_tc = Noto_Sans_TC({
    subsets: ['latin'],
    weight: ['400', '700'],
});
export const noto_sans_jp = Noto_Sans_JP({
    subsets: ['latin'],
    weight: ['400', '700'],
});
export const noto_sans = Noto_Sans({
    subsets: ['latin'],
    weight: ['400', '700'],
});

export const InitFont = () => {
    const { lang } = useParams();

    const language =
        defaultLanguages.find((l) => l.path === `/${lang}`)?.iso || defaultLanguage.iso;

    const font =
        language === 'ko-KR'
            ? noto_sans_kr
            : language === 'zh-Hans-CN'
              ? noto_sans_sc
              : language === 'zh-Hant-TW'
                ? noto_sans_tc
                : noto_sans;

    useEffect(() => {
        document.body.classList.add(font.className);
        document.body.style.setProperty('--noto-sans', font.style.fontFamily);

        return () => {
            document.body.classList.remove(font.className);
            document.body.style.removeProperty('--noto-sans');
        };
    }, [font]);

    useEffect(() => {
        document.head.setAttribute('lang', language);
        document.head.setAttribute('dir', language.startsWith('ar') ? 'rtl' : 'ltr');
    }, [language]);

    return <></>;
};
