import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Saira_Stencil_One\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--saira-font\"}],\"variableName\":\"saira\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\"],\"subsets\":[\"latin\"],\"variable\":\"--poppins-font\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/a11y.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/effect-fade.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["InitFont"] */ "/app/src/components/font/InitFont.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/features/authentication/context/Session.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cookies"] */ "/app/src/features/cookies/components/Cookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GTMInit"] */ "/app/src/features/google-tag-manager/components/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UpdateOnetrustLanguage"] */ "/app/src/features/onetrust/components/update-onetrust-language/UpdateOnetrustLanguage.component.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.scss");
