export const defaultLanguage = { label: 'English', iso: 'en', match: 'en', path: '/en' };

export const defaultLanguages = [
    defaultLanguage,
    { label: '한국어', iso: 'ko-KR', match: 'ko', path: '/ko-kr' },
    { label: 'Español', iso: 'es-MX', match: 'es-mx', path: '/es-mx' },
    { label: 'Deutsch', iso: 'de-DE', match: 'de', path: '/de-de' },
    { label: 'Français', iso: 'fr-FR', match: 'fr', path: '/fr-fr' },
    { label: '日本語', iso: 'ja-JP', match: 'jp', path: '/ja-jp' },
    { label: 'Português', iso: 'pt-BR', match: 'pt', path: '/pt-br' },
];
