'use client';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

// This component is used to update the language of the OneTrust cookie banner.
// https://developer.onetrust.com/onetrust/docs/javascript-api#change-language
export const UpdateOnetrustLanguage = ({ lang }: { lang: string }) => {
    const path = usePathname();
    const language = path.split('/')[1].split('-')[0].toLocaleLowerCase();

    useEffect(() => {
        const checkOneTrust = () => {
            if (window.OneTrust) {
                try {
                    if (window.OneTrust.IsAlertBoxClosed && !window.OneTrust.IsAlertBoxClosed()) {
                        window.OneTrust.changeLanguage && window.OneTrust.changeLanguage(language);
                    }
                } catch (error) {
                    console.error('Error updating OneTrust language:', error);
                }
            } else {
                setTimeout(checkOneTrust, 100); // Check again after 100ms
            }
        };

        checkOneTrust();
    }, [language, lang]);

    return <></>;
};
